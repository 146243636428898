import { MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./HorizontalLayout.module.css";

const COLLAPSE_THRESHOLD_PX = 200;

export interface IHorizontalSplitterState {
  leftPanelWidthPx: number,
  rightPanelWidthPx: number,
}

export default function HorizontalLayout(props: {
  keyForStorage?: string,
  minimizedTitle: string,
  collapsiblePanel?: string, // "left" (default) or "right"; the panel that is collapsible
  leftContent: ReactNode,
  rightContent: ReactNode,
  className?: string,
  togglerClassName?: string,
  leftPanelClassName?: string,
  rightPanelClassName?: string,
  onSplitterChange?: (state: IHorizontalSplitterState) => void,
}): ReactElement {
  let leftPanelRef = useRef<HTMLDivElement>(null);
  let rightPanelRef = useRef<HTMLDivElement>(null);
  let containerRef = useRef<HTMLDivElement>(null);
  let splitterRef = useRef<HTMLDivElement>(null);
  let togglerRef = useRef<HTMLButtonElement>(null);

  const collapsiblePanel = props.collapsiblePanel ?? "left";
  const className = props.className ?? "";
  const togglerClassName = props.togglerClassName ?? styles.toggler;
  const leftPanelClassName = props.leftPanelClassName ?? styles.left__panel;
  const rightPanelClassName = props.rightPanelClassName ?? styles.right__panel;

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isSplitterMouseDown, setIsSplitterMouseDown] = useState<boolean>(false);
  const [leftPanelWidthState, setLeftPanelWidthState] = useState<string>("460px");
  const [rightPanelWidthState, setRightPanelWidthState] = useState<string>("460px");

  const movingSplitterClass = isSplitterMouseDown ? styles.moving_splitter_parent : "";
  const movingSplitterPointerEventsClass = isSplitterMouseDown ? styles.moving_splitter_pointer_events : "";

  const leftPanelWidth = collapsiblePanel==="left" && collapsed ? "0" : leftPanelWidthState;
  const rightPanelWidth = collapsiblePanel!=="left" && collapsed ? "0" : rightPanelWidthState;

  const storageKey = props.keyForStorage === undefined ? null : `horizontalLayout-${collapsiblePanel}-width-${props.keyForStorage}`;
  const toggleStateStorageKey = props.keyForStorage === undefined ? null : `horizontalLayout-state-${props.keyForStorage}`;


  function storeSidebarWidth() {
    if (storageKey !== null) {
      if (collapsiblePanel==="left")
        localStorage.setItem(storageKey, leftPanelWidthState);
      else
        localStorage.setItem(storageKey, rightPanelWidthState);
    }
  }


  const loadSidebarWidth = useCallback(() => {
    if (storageKey !== null) {
      const storedSidebarWidth = localStorage.getItem(storageKey);
      if (storedSidebarWidth !== null) {
        if (collapsiblePanel==="left")
          setLeftPanelWidthState(storedSidebarWidth);
        else
          setRightPanelWidthState(storedSidebarWidth);
      }
    }
    console.log(`togglerClassName=${togglerClassName}`);
    if (togglerClassName) {
      if (toggleStateStorageKey !== null) {
        const state = localStorage.getItem(toggleStateStorageKey);
        setCollapsed(state === "true");
      }
    }
    else {
      setCollapsed(true);
    }
  }, [storageKey]);


  function onTogglerClicked() {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    if (toggleStateStorageKey !== null) {
      localStorage.setItem(toggleStateStorageKey, newCollapsed ? "true" : "false");
    }
  }


  function onSplitterMove(e: MouseEvent<HTMLDivElement>) {
    if (isSplitterMouseDown && !!containerRef.current) {
      const parentBoundingBox = containerRef.current.getBoundingClientRect();
      const parentWidth = parentBoundingBox.width ?? 0;
      const newLeftWidth = e.clientX - parentBoundingBox.x;
      const newRightWidth = parentWidth - newLeftWidth;
      if (collapsiblePanel==="left") {
        const leftPercentage = newLeftWidth / parentWidth * 100;
        if (e.movementX<0 && newLeftWidth < COLLAPSE_THRESHOLD_PX) {
          setCollapsed(true);
          setIsSplitterMouseDown(false);
        } else {
          setLeftPanelWidthState(`${leftPercentage}%`);
        }
      }
      else {
        const togglerMargin = parseInt(window.getComputedStyle(togglerRef.current!).marginLeft) + parseInt(window.getComputedStyle(togglerRef.current!).marginRight);
        //Note that this (adding togglerMargin) is rather empirical, but it works
        const rightPercentage = (newRightWidth + togglerMargin) / parentWidth * 100;
        if (e.movementX>0 && newRightWidth < COLLAPSE_THRESHOLD_PX) {
          setCollapsed(true);
          setIsSplitterMouseDown(false);
        } else {
          setRightPanelWidthState(`${rightPercentage}%`);
        }
      }
      //console.log(`${leftPanelRef.current?.clientWidth}+${rightPanelRef.current?.clientWidth}=${(leftPanelRef.current?.clientWidth??0)+(rightPanelRef.current?.clientWidth??0)} ... ${containerRef.current.clientWidth}`);
      if (props.onSplitterChange) {
        props.onSplitterChange({leftPanelWidthPx: newLeftWidth, rightPanelWidthPx: newRightWidth,});
      }
    }
  }


  function onSplitterMouseDown(e: MouseEvent<HTMLDivElement>) {
    setIsSplitterMouseDown(true);
  }

  
  function onSplitterMouseUp(e: MouseEvent<HTMLDivElement>) {
    if (isSplitterMouseDown) {
      if (e.clientX >= COLLAPSE_THRESHOLD_PX) {
        storeSidebarWidth();
      }
      setIsSplitterMouseDown(false);
    }
  }


  useEffect(() => {
    loadSidebarWidth();
  }, [props.keyForStorage, loadSidebarWidth]);


  return (
    <>
      <div ref={containerRef} className={`cy__content ${className} ${movingSplitterClass}`} onMouseMove={onSplitterMove} onMouseUp={onSplitterMouseUp}>
        <div ref={leftPanelRef} 
             className={`${movingSplitterPointerEventsClass} ${leftPanelClassName} ${collapsiblePanel==="left" && collapsed ? styles.collapsed : ""}`}
             style={collapsiblePanel==="left" ?{
              minWidth: leftPanelWidth,
              maxWidth: leftPanelWidth,
            }:{}}>
          {props.leftContent}
        </div>

        <div ref={splitterRef}
          className={styles.splitter}
          onMouseDown={onSplitterMouseDown}
          hidden={collapsed}
        />
        <button ref={togglerRef} id="toggler" type="button" style={{display: togglerClassName ? "block" : "none"}}
                className={`${togglerClassName} ${collapsiblePanel} ${collapsed ? "collapsed" : "shown"}`}
                onClick={onTogglerClicked}
        />
        <div ref={rightPanelRef} 
             className={`${movingSplitterPointerEventsClass} ${rightPanelClassName} ${collapsiblePanel!=="left" && collapsed ? styles.collapsed : ""}`}
             style={collapsiblePanel!=="left" ?{
                    minWidth: rightPanelWidth,
                    maxWidth: rightPanelWidth,
                  }:{}}
        >
          {props.rightContent}
        </div>
      </div>
    </>
  );
}
